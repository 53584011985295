import { useCallback, useState } from "react";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ClassificationButton from "./classifications/ClassificationButton";
import {
  iChipItem,
  iClassificationItem,
  iConfiguration,
  iConfigurationLearningObjective,
} from "./classifications/interfaces";
import {
  Checkbox,
  CheckboxChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
  TextArea,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { useAppContext } from "./AppContext";
import { iCustomer } from "../../interfaces/iCustomer";
import { StatusDto } from "../../api/learnable_question/QuestionClient";
import { iInfo } from "../../interfaces/iInfo";
import {
  CONFIG_AMNEN_CLASSIFICATION_KEY,
  CONFIG_BEHORIGHET_CLASSIFICATION_KEY,
  CONFIG_FRAGETYP_CLASSIFICATION_KEY,
  KEY_INFO_AMNEN_CLASSIFICATIONS,
  KEY_INFO_BEHORIGHET_CLASSIFICATIONS,
  KEY_INFO_COMMENT,
  KEY_INFO_CUSTOMER,
  KEY_INFO_DEPRECATED,
  KEY_INFO_EXTERNAL_ID,
  KEY_INFO_FRAGETYP_CLASSIFICATIONS,
  KEY_INFO_KNOWLEDGE_LEVEL,
  KEY_INFO_LEARNING_OBJECTIVES,
  KEY_INFO_STATUS,
  KEY_INFO_SUBTYPE,
  KEY_INFO_WHERE_USED_INTERACTIONS,
  KEY_INFO_WHERE_USED_LEARNINGOBJECTS,
  KEY_INFO_WHERE_USED_LEARNINGSTRUCTURES,
  LEARNING_OBJECTIVES_ROOT_ID,
} from "./Constants";

import { ContainerSubTypeDto, WhereUsedType } from "../../api/Client";
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { ConfigProvider, filterLearningObjective } from "./classifications/types";
import { ClassificationKey } from "./classifications/enums";
import { getLearningObjectives } from "./classifications/helperFunctions";
import {
  interactionParams,
  openEducation,
  openInteraction,
  openLearningObject,
} from "../question/edit/functions/helperFunctions";
import ItemViewer from "./classifications/ItemViewer";

const Info = (props: any) => {
  const appObject = useAppContext();
  const configProviderClassification: ConfigProvider = props.configProviderClassification;
  const configurationData: iConfiguration = props.configuration; //appObject.configurationData;
  const infoData: iInfo = props.infoData;
  const onInfoChanged = props.onInfoChanged;
  const containerSubTypes: ContainerSubTypeDto[] = props.containerSubTypes;
  const configUtbildningar = configurationData.classifications!.find(
    (x) => x.key === CONFIG_BEHORIGHET_CLASSIFICATION_KEY
  );

  console.log("info", props);
  // const filterClassification = getClassificationContainerCopyByRootIds(
  //   configUtbildningar!.rootIds,
  //   classificationContainer
  // );

  const getInfoCopy = (): iInfo => {
    let newInfo: iInfo = { ...infoData };
    newInfo.selectedItems = infoData.selectedItems.map((x) => ({ ...x })) as iClassificationItem[];
    return newInfo;
  };

  const handleClassificationChange = (items: iClassificationItem[], rootIds: string[]) => {
    let newInfo = getInfoCopy();

    newInfo.selectedItems = newInfo.selectedItems.filter((x) => !rootIds.includes(x.rootId!));
    newInfo.selectedItems = newInfo.selectedItems.concat(items);

    onInfoChanged(newInfo);
  };

  const onCustomerChange = (event: DropDownListChangeEvent) => {
    let newInfo = getInfoCopy();

    newInfo.customerId = (event.target.value as iCustomer).id;

    onInfoChanged(newInfo);
  };

  const onStatusChange = (event: DropDownListChangeEvent) => {
    let newInfo = getInfoCopy();

    newInfo.statusId = (event.target.value as StatusDto).id!;

    onInfoChanged(newInfo);
  };

  const onDeprecatedChange = (event: CheckboxChangeEvent) => {
    let newInfo = getInfoCopy();

    newInfo.deprecated = event.value;

    onInfoChanged(newInfo);
  };

  const onKnowledgeLevelChange = (event: NumericTextBoxChangeEvent) => {
    let newInfo = getInfoCopy();

    newInfo.knowledgeLevel = event.value!;

    onInfoChanged(newInfo);
  };

  const onCommentChange = (event: TextAreaChangeEvent) => {
    let newInfo = getInfoCopy();

    newInfo.comment = event.value! as string;

    onInfoChanged(newInfo);
  };

  const onSubTypeChange = (event: DropDownListChangeEvent) => {
    let newInfo = getInfoCopy();

    newInfo.subTypeId = (event.target.value as ContainerSubTypeDto).id!;

    onInfoChanged(newInfo);
  };

  const infoElementVisible = useCallback(
    (elementName: string) => {
      return infoData.visibleElements.includes(elementName);
    },
    [infoData.visibleElements]
  );

  const [expanded, setExpanded] = useState<boolean>(props.expanded ?? true);

  const title: string = props.title ?? "[Metadata saknas i props]";

  const isClassificationsButtonVisible = (key: any): boolean => {
    if (key === CONFIG_FRAGETYP_CLASSIFICATION_KEY) return infoElementVisible(KEY_INFO_FRAGETYP_CLASSIFICATIONS);
    if (key === CONFIG_BEHORIGHET_CLASSIFICATION_KEY) return infoElementVisible(KEY_INFO_BEHORIGHET_CLASSIFICATIONS);
    if (key === CONFIG_AMNEN_CLASSIFICATION_KEY) return infoElementVisible(KEY_INFO_AMNEN_CLASSIFICATIONS);

    if (key === LEARNING_OBJECTIVES_ROOT_ID) return infoElementVisible(KEY_INFO_LEARNING_OBJECTIVES);

    return false;
  };
  const learningObjectiveConfiguration: iConfigurationLearningObjective = {
    title: "Lärandemål",
    rootId: "learningObjectivesRootId",
    showSelected: true,
    clientGet: getLearningObjectives,
  };
  let filterProvider = filterLearningObjective;
  filterProvider.configClassificationProvider = configProviderClassification;
  filterProvider.classificationKey = ClassificationKey.behorigheter;
  const getWhereUsedItems = (whereUsedType: WhereUsedType): iChipItem[] => {
    if (infoData.whereUsed === undefined) return [];
    const whereUsedItems = infoData.whereUsed.filter((x: any) => x.type === whereUsedType);
    return whereUsedItems.map((x: any) => {
      return {
        value: x.id,
        text: x.id + " - " + x.name,
        isUsed: true,
        onClick:
          whereUsedType === WhereUsedType._1
            ? openLearningObject.bind(null, x.id)
            : openInteraction.bind(null, { interactionId: x.id } as interactionParams),
      };
    });
  };
  const getWhereUsedItemsLearningStructure = (): iChipItem[] => {
    if (infoData.whereUsed === undefined) return [];
    const whereUsedItems = infoData.whereUsed.filter((x: any) => x.type === WhereUsedType._4);
    return whereUsedItems.map((x: any) => {
      return {
        value: x.id,
        text: x.id + " - " + x.name,
        isUsed: true,
        onClick: openEducation.bind(null, x.id, props.contentId),
      };
    });
  };
  return (
    <>
      <ExpansionPanel
        style={{ borderRadius: "5px", marginTop: "2px", marginBottom: "2px" }}
        title={title}
        expanded={expanded}
        tabIndex={0}
        key={1}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded(!event.expanded);
        }}
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent onKeyDown={(e) => e.stopPropagation()}>
              <div className="m-1 row">
                {infoElementVisible(KEY_INFO_EXTERNAL_ID) && <div className="col-3">Externt ID:</div>}
              </div>
              <div className="m-1 row">
                {infoElementVisible(KEY_INFO_EXTERNAL_ID) && (
                  <div className="col-3 ">
                    <span>{infoData.externalId ? infoData.externalId.substring(0, 8) : ""}</span>
                  </div>
                )}
              </div>
              <div className="m-1 row">
                {infoElementVisible(KEY_INFO_CUSTOMER) && <div className="col-2">Organisation:</div>}
                {infoElementVisible(KEY_INFO_STATUS) && <div className="col-2">Status:</div>}
                {/* {infoElementVisible(KEY_INFO_WHERE_USED_INTERACTIONS) && (
                  <div className="col-2">Ingår i interaktion:</div>
                )}
                {infoElementVisible(KEY_INFO_WHERE_USED_LEARNINGOBJECTS) && (
                  <div className="col-2">Ingår i objekt:</div>
                )} */}
              </div>

              <div className="m-1 row">
                {infoElementVisible(KEY_INFO_CUSTOMER) && (
                  <div className="col-2 ">
                    <DropDownList
                      data={appObject.customers}
                      onChange={onCustomerChange}
                      style={{ width: "150px" }}
                      textField="name"
                      dataItemKey="id"
                      value={appObject.customers.find((x) => x.id === infoData.customerId)}
                    />
                  </div>
                )}
                {infoElementVisible(KEY_INFO_STATUS) && (
                  <div className="col-2 ">
                    <DropDownList
                      data={appObject.statuses}
                      onChange={onStatusChange}
                      style={{ width: "150px" }}
                      textField="name"
                      dataItemKey="id"
                      value={appObject.statuses.find((x) => x.id === infoData.statusId)}
                    />
                  </div>
                )}
              </div>
              <div className="m-1 mt-3 row">
                {isClassificationsButtonVisible(CONFIG_FRAGETYP_CLASSIFICATION_KEY) && (
                  <div className="col-2" key={KEY_INFO_FRAGETYP_CLASSIFICATIONS}>
                    <ClassificationButton
                      configProvider={configProviderClassification}
                      filterProvider={undefined}
                      classificationKey={ClassificationKey.andamal}
                      configurationClassificationOptional={undefined}
                      onOkClick={handleClassificationChange}
                      selectedIds={infoData.selectedItems ? infoData.selectedItems.map((x) => x.identifier) : []}
                      readOnly={false}
                    />
                  </div>
                )}
                {isClassificationsButtonVisible(CONFIG_BEHORIGHET_CLASSIFICATION_KEY) && (
                  <div className="col-2" key={KEY_INFO_BEHORIGHET_CLASSIFICATIONS}>
                    <ClassificationButton
                      configProvider={configProviderClassification}
                      filterProvider={undefined}
                      classificationKey={ClassificationKey.behorigheter}
                      configurationClassificationOptional={undefined}
                      onOkClick={handleClassificationChange}
                      selectedIds={infoData.selectedItems ? infoData.selectedItems.map((x) => x.identifier) : []}
                      readOnly={false}
                      whereUsed={infoData.whereUsed}
                    />
                  </div>
                )}
                {infoElementVisible(KEY_INFO_WHERE_USED_LEARNINGSTRUCTURES) && (
                  <div className="col-2">
                    <div className="mb-3">Utbildningsstrukturer:</div>
                    <ItemViewer data={getWhereUsedItemsLearningStructure()} type="chip" />
                  </div>
                )}
                {infoElementVisible(KEY_INFO_LEARNING_OBJECTIVES) && (
                  <div className="col-2" key={KEY_INFO_LEARNING_OBJECTIVES}>
                    <ClassificationButton
                      configProvider={undefined}
                      filterProvider={filterLearningObjective}
                      classificationKey={ClassificationKey.larandemal}
                      configurationClassificationOptional={learningObjectiveConfiguration}
                      onOkClick={handleClassificationChange}
                      selectedIds={
                        infoData.selectedItems
                          ? infoData.selectedItems
                              .filter((x) => x.rootId === LEARNING_OBJECTIVES_ROOT_ID)
                              .map((x) => x.identifier)
                          : []
                      }
                      readOnly={false}
                    />
                  </div>
                )}
                {infoElementVisible(KEY_INFO_WHERE_USED_INTERACTIONS) && (
                  <div className="col-2">
                    <div className="mb-2">Ingår i interaktion</div>
                    <ItemViewer data={getWhereUsedItems(WhereUsedType._2)} type="chip" />
                  </div>
                )}
                {infoElementVisible(KEY_INFO_WHERE_USED_LEARNINGOBJECTS) && (
                  <div className="col-2">
                    <div className="mb-2">Ingår i objekt</div>
                    <ItemViewer data={getWhereUsedItems(WhereUsedType._1)} type="chip" />
                  </div>
                )}
              </div>
              {/* {configurationData.classifications!.map(
                  (configuration: iConfigurationClassification) =>
                    isClassificationsButtonVisible(configuration.key) && (
                      <div className="col-2" key={configuration.key}>
                        <ClassificationButton
                          configurations={configuration}
                       
                          onApply={handleClassificationChange}
                          selectedClassificationIds={
                            infoData.selectedItems ? infoData.selectedItems.map((x) => x.identifier) : []
                          }
                        />
                      </div>
                    )
                )} */}

              <div className="m-1 row">
                {infoElementVisible(KEY_INFO_SUBTYPE) && <div className="col-2">Typ:</div>}
                {infoElementVisible(KEY_INFO_DEPRECATED) && <div className="col-2">Borttagen:</div>}
                {infoElementVisible(KEY_INFO_KNOWLEDGE_LEVEL) && <div className="col-2">Kunskapsnivå:</div>}
              </div>

              <div className="m-1 row">
                {infoElementVisible(KEY_INFO_SUBTYPE) && (
                  <div className="col-2 ">
                    <DropDownList
                      data={containerSubTypes}
                      // data={[
                      //   { id: 1, name: "Fråga" },
                      //   { id: 2, name: "Svar" },
                      // ]}
                      onChange={onSubTypeChange}
                      style={{ width: "150px" }}
                      textField="name"
                      dataItemKey="id"
                      value={containerSubTypes.find((x) => x.id === infoData.subTypeId)}
                    />
                  </div>
                )}
                {infoElementVisible(KEY_INFO_DEPRECATED) && (
                  <div className="col-2 ">
                    <Checkbox
                      onChange={(e: CheckboxChangeEvent) => onDeprecatedChange(e)}
                      value={infoData.deprecated}
                    />
                  </div>
                )}

                {infoElementVisible(KEY_INFO_KNOWLEDGE_LEVEL) && (
                  <div className="col-2 ">
                    <NumericTextBox
                      onChange={(e: NumericTextBoxChangeEvent) => onKnowledgeLevelChange(e)}
                      value={infoData.knowledgeLevel}
                      min={1}
                      style={{ width: 100 }}
                    />
                  </div>
                )}
              </div>
              <div className="m-1 row">
                {infoElementVisible(KEY_INFO_COMMENT) && <div className="col-2">Kommentar:</div>}
              </div>
              <div className="m-1 mb-2 row">
                {infoElementVisible(KEY_INFO_COMMENT) && (
                  <div className="col-2 ">
                    <TextArea
                      style={{ width: 500 }}
                      onChange={(e: TextAreaChangeEvent) => onCommentChange(e)}
                      value={infoData.comment}
                    />
                  </div>
                )}
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>
    </>
  );
};

export default Info;
