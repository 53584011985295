import { Checkbox, Input } from "@progress/kendo-react-inputs";

import { ComboBox } from "@progress/kendo-react-dropdowns";

import React, { useState, useEffect } from "react";
import FilterExpansionPanel from "../common/FilterExpansionPanel";

import MainGrid from "../common/MainGrid";
import SearchContainer from "../common/SearchContainer";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { SearchClient } from "../../api/learnable_search/SearchClient";
import { ClassificationDto } from "../../api/learnable_classification/ClassificationClient";
import { GridRowClickEvent } from "@progress/kendo-react-grid";
import { iColumn } from "../../interfaces/iColumn";
import ClassificationButton from "../common/classifications/ClassificationButton";

import { Button } from "@progress/kendo-react-buttons";
import { appConfig } from "../../appConfig";
//import { openQuestion, openQuestionPreview } from "./edit/functions/helperFunctions";
//import { copyQuestion } from "./edit/functions/questionDtoFunctions";
import { ClassificationContainer, filterLearningObjective } from "../common/classifications/types";
import {
  iClassificationItem,
  iConfiguration,
  iConfigurationLearningObjective,
} from "../common/classifications/interfaces";

import { fetchContainerSubTypes } from "../../api/learnable_classification/apiFunctions";
import {
  Client,
  CustomFilterDto,
  GetMyLatestItemsResponse,
  GetFilterRequest,
  GetLearningObjectRequest,
} from "../../api/Client";
import { iCustomFilterDtoExtended } from "../../interfaces/iCustomFilterDtoExtended";
import { useAppContext } from "../common/AppContext";
// import MetadataPanel from "./MetadataPanel";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import {
  interactionParams,
  openInteraction,
  openLearningObjectPreview,
} from "../question/edit/functions/helperFunctions";
import {
  // AMNE_CLASSIFICATION_ID,
  // BEHORERHET_CLASSIFICATION_ID,
  // FRAGESTYP_CLASSIFICATION_ID,
  // BEHORERHET_FM_CLASSIFICATION_ID,
  CONFIG_AMNEN_CLASSIFICATION_KEY,
  CONFIG_BEHORIGHET_CLASSIFICATION_KEY,
  CONFIG_FRAGETYP_CLASSIFICATION_KEY,
  CONTAINER_TYPE_INTERACTION,
} from "../common/Constants";
import { copyContainer, fetchConfiguration } from "../../api/learnable_lcms/apiFunctions";
import { getTranslation } from "../common/helperFunctions";
import CustomDialog from "../common/CustomDialog";
import { InteractionsUtilities } from "../common/SearchInteractionsUtilities";
import { iContainerSubTypeDtoExtended } from "../../interfaces/iContainerSubTypeDtoExtended";
import { MenuIndex } from "../common/Enums";
import { ConfigProvider } from "../common/classifications/types";
import { ClassificationKey, Service } from "../common/classifications/enums";
import { getLearningObjectives, getSelectedItems } from "../common/classifications/helperFunctions";

const Interactions = (props: any) => {
  const [organisationer, setOrganisationer] = useState<any[] | undefined>(undefined);
  const appObject = useAppContext();
  const [configData, setConfigData] = useState<iConfiguration | undefined>(undefined);
  const userId: number = appObject.userId;
  console.log("appObject", appObject);

  const searchUtilities: iSearchUtilities = new InteractionsUtilities();

  // Custom filter
  const getInitialCustomFilter = () => {
    const customFilter: iCustomFilterDtoExtended = new CustomFilterDto() as iCustomFilterDtoExtended;
    customFilter.amneGuids = [];
    customFilter.behorighetGuids = [];
    customFilter.fragetypGuids = [];
    customFilter.larandemalGuids = [];
    customFilter.name = "";
    customFilter.fritext = "";
    customFilter.organistaionsId = "";
    customFilter.interaktionstypId = 0;
    customFilter.harBild = false;
    customFilter.harVideo = false;
    customFilter.ingenMedia = false;
    customFilter.isNew = true;
    customFilter.endastFragetext = false;
    customFilter.includeDeprecated = false;

    return customFilter;
  };

  const [containerSubTypesLearningObject, setContainerSubTypesLearningObject] = useState<
    iContainerSubTypeDtoExtended[] | undefined
  >([]);

  const [customFilters, setCustomFilters] = useState<iCustomFilterDtoExtended[]>([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState<iCustomFilterDtoExtended>(getInitialCustomFilter());

  const [refreshGrid, setRefreshGrid] = useState<number>(1);

  const copyInteractionButtonClick = async (interactionId: number) => {
    // const newContainerId: number | undefined = await copyLearningObjective(learningObjectId);
    // let success: boolean = newContainerId > 0;
    // if (success) {
    //   openLearningObject(newContainerId);
    // } else {
    //   console.log("Lärobjektet kunde inte kopieras");
    // }
    const newContainerId: number | undefined = await copyContainer(interactionId);
    let success: boolean = newContainerId > 0;
    if (success) {
      openInteraction({ interactionId: newContainerId, interactionType: 0, questionId: 0, templateId: 0 });
    } else {
      console.log("Interaktionen kunde inte kopieras");
    }
  };

  const ColumnButtons: any = (props: any) => {
    return (
      <td>
        <div className="centerMiddle ">
          <Button
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="copy"
            onClick={() => copyInteractionButtonClick(props.dataItem.id)}
          ></Button>

          <Button
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="preview"
            className="ms-1"
            onClick={() => openLearningObjectPreview(props.dataItem.id)}
          ></Button>

          <Button
            title={props.dataItem.comment}
            disabled={props.dataItem.comment && props.dataItem.comment.length > 0 ? false : true}
            onClick={() => {
              setShowCustomDialog({
                title: "Kommentar",
                message: props.dataItem.comment,
                onClose: () => setShowCustomDialog(null),
              });
            }}
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="comment"
            className="ms-1"
          ></Button>
        </div>
      </td>
    );
  };

  const GridColumns: iColumn[] = [
    { field: "id", filter: "numeric", title: "ID", width: 170, show: true },
    { field: "containerSubtype", filterable: false, title: "containerSubtype", width: 100, show: false },
    { field: "containerSubtypeName", filterable: false, title: "Typ", width: 140, show: true },
    { field: "title", title: "Namn", filterable: false, show: true },
    { field: "description", title: "Beskrivning", filterable: false, show: true },
    { field: "senastEditerad", title: "Senast ändrad", filter: "date", format: "{0:d}", width: 260, show: true },
    { field: "senastEditeradAv", title: "Senast ändrad av", filterable: false, width: 120, show: true },
    { field: "", title: "", width: 100, show: !props.onSelectInteraction, filterable: false, cell: ColumnButtons },
  ];

  const [gridData, setGridData] = React.useState<any[]>([]);

  const initialSort: Array<SortDescriptor> = [{ field: "senastEditerad", dir: "desc" }];

  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  const [gridFilter, setGridFilter] = useState<CompositeFilterDescriptor | undefined>(initialFilter);
  const [filterModel, setFilterModel] = React.useState({
    subClassifications: [],
    behClassifications: [],
    typClassifications: [],
    media: [],
    sampleTextbox: "aa",
    senastEditerad: "2023-01-13",
    sampleCombobox: { id: 2, name: "Tennis" },
    sampleCheckbox: false,
  });

  const getFilterAttribute = (attr: string, value: string) => {
    let newAttribute = { [attr]: value };

    return newAttribute;
  };

  const getSelectedValue = (id: any | undefined, list: any[] | undefined) => {
    let value: any | undefined = id && list && list.find((e) => e.id === id);
    if (value === undefined) {
      return null;
    }

    return value;
  };

  const [classificationContainerAmne, setClassificationContainerAmne] = useState<ClassificationContainer | undefined>(
    undefined
  );

  const [classificationContainerBehorighet, setClassificationContainerBehorighet] = useState<
    ClassificationContainer | undefined
  >(undefined);

  const [classificationContainerFragetyp, setClassificationContainerFragetyp] = useState<
    ClassificationContainer | undefined
  >(undefined);

  const [containerLearningObjectives, setContainerLearningObjectives] = useState<ClassificationContainer | undefined>(
    undefined
  );

  const onApply = (classificationType: string, selectedItems: iClassificationItem[]) => {
    const selectedGuids = selectedItems.map((i) => i.identifier);
    switch (classificationType) {
      case CONFIG_AMNEN_CLASSIFICATION_KEY:
        // setClassificationContainerAmne({ ...classificationContainerAmne!, selected: selectedItems });
        onElementValueChanged("amneGuids", selectedGuids);
        break;
      case CONFIG_BEHORIGHET_CLASSIFICATION_KEY:
        console.log("behorighetClassificationId", selectedGuids);
        // setClassificationContainerBehorighet({ ...classificationContainerBehorighet!, selected: selectedItems });
        onElementValueChanged("behorighetGuids", selectedGuids);
        break;
      case CONFIG_FRAGETYP_CLASSIFICATION_KEY:
        // setClassificationContainerFragetyp({ ...classificationContainerFragetyp!, selected: selectedItems });
        onElementValueChanged("fragetypGuids", selectedGuids);
        break;
      case "learningObjectives":
        // setContainerLearningObjectives({ ...containerLearningObjectives!, selected: selectedItems });
        onElementValueChanged("larandemalGuids", selectedGuids);
        break;
    }
  };

  const defaultMediaCheckboxes = {
    harVideo: false,
    harBild: false,
    ingenMedia: false,
  };

  const [mediaCheckboxes, setMediaCheckboxes] = useState(defaultMediaCheckboxes);
  const configProviderClassification: ConfigProvider = {
    service: Service.lcms,
    url: appConfig.REACT_APP_QUESTION_API_URL as string,
    key: "classificationList",
  };

  const learningObjectiveConfiguration: iConfigurationLearningObjective = {
    title: "Lärandemål",
    rootId: "learningObjectivesRootId",
    showSelected: false,
    clientGet: getLearningObjectives,
  };

  let filterProvider = filterLearningObjective;
  filterProvider.configClassificationProvider = configProviderClassification;
  filterProvider.classificationKey = ClassificationKey.behorigheter;

  const filterControls = [
    <ClassificationButton
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.amnen}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_AMNEN_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_AMNEN_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.andamal}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_FRAGETYP_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_FRAGETYP_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.behorigheter}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_BEHORIGHET_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_BEHORIGHET_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      configProvider={undefined}
      filterProvider={filterProvider}
      classificationKey={ClassificationKey.larandemal}
      configurationClassificationOptional={learningObjectiveConfiguration}
      readOnly={false}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply("learningObjectives", selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, "learningObjectives") ?? []}
    />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Ämnen"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_AMNEN_CLASSIFICATION_KEY, selectedItems)}
    //   displaySelected={false}
    //   configurations={getLcmsAmnenConfigurations()}
    //   selectedClassificationIds={getSelectedItems(selectedCustomFilter, CONFIG_AMNEN_CLASSIFICATION_KEY)}
    //   dialogTitle="Välj ämnen"
    //   // {...getFilterAttribute("searchfilterlabel", "Ämne")}
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Ändamål"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_FRAGETYP_CLASSIFICATION_KEY, selectedItems)}
    //   displaySelected={false}
    //   configurations={getLcmsAndamalConfigurations()}
    //   selectedClassificationIds={getSelectedItems(selectedCustomFilter, CONFIG_FRAGETYP_CLASSIFICATION_KEY)}
    //   dialogTitle="Välj ändamål"
    //   // {...getFilterAttribute("searchfilterlabel", "Ändamål")}
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Utbildningar"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_BEHORIGHET_CLASSIFICATION_KEY, selectedItems)}
    //   displaySelected={false}
    //   dialogTitle="Välj utbildningar"
    //   configurations={getLcmsEductionConfigurations()}
    //   selectedClassificationIds={getSelectedItems(selectedCustomFilter, CONFIG_BEHORIGHET_CLASSIFICATION_KEY)}
    //   // {...getFilterAttribute("searchfilterlabel", "Utbildningar")}
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Lärandemål"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply("learningObjectives", selectedItems)}
    //   displaySelected={false}
    //   dialogTitle="Välj lärandemål"
    //   classificationContainer={containerLearningObjectives}
    //   filterControls={true}
    //   filterClassification={classificationContainerBehorighet}
    //   configurationData={configData}
    //   // {...getFilterAttribute("searchfilterlabel", "Lärandemål")}
    // />,
    <div {...getFilterAttribute("searchfilterlabel", "Fritext")}>
      <Input
        name="fritext"
        value={selectedCustomFilter.fritext}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
        onKeyDown={(e: any) => onSearchKeyDown(e)}
        {...getFilterAttribute("searchfilterlabel", "Fritext")}
      />
      <br />
    </div>,
    <ComboBox
      name="organistaionsId"
      textField="name"
      dataItemKey="id"
      value={getSelectedValue(selectedCustomFilter.organistaionsId, organisationer)}
      data={organisationer}
      onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
      {...getFilterAttribute("searchfilterlabel", "Organisation")}
    />,
    <ComboBox
      name="containerSubType"
      textField="text"
      dataItemKey="id"
      data={containerSubTypesLearningObject}
      value={getSelectedValue(selectedCustomFilter.containerSubType, containerSubTypesLearningObject)}
      onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
      {...getFilterAttribute("searchfilterlabel", "Typ")}
    />,
    <></>,
    <></>,
    <></>,
    <></>,
    <div>
      <div style={{ height: "20px" }}> </div>
      <Checkbox
        name="includeDeprecated"
        value={selectedCustomFilter.includeDeprecated}
        label={"Inkludera borttagna"}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
      />
    </div>,
  ];

  const [amnen, setAmnen] = useState<ClassificationDto[] | null>(null);
  const [behorigheter, setBehorigheter] = useState<ClassificationDto[] | null>(null);
  const [fragetyper, setFragetyper] = useState<ClassificationDto[] | null>(null);

  // const getClassificationsRequest = (configData: iConfiguration): GetClassificationsRequest => {
  //   let getClassificationsRequest = new GetClassificationsRequest();
  //   getClassificationsRequest.classificationIds = getClassificationRootIds(configData);
  //   getClassificationsRequest.includeDescendants = true;
  //   getClassificationsRequest.cultureName = "sv-SE";

  //   return getClassificationsRequest;
  // };

  // const getClassifications = async (configData: iConfiguration) => {
  //   var result = await fetchClassifications(getClassificationsRequest(configData));

  //   //Hantera ämnen
  //   let classificationContainer: ClassificationContainer = getEmptyClassificationContainer();
  //   let rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_AMNEN_CLASSIFICATION_KEY);
  //   let classificationAmnen = getClassificationByRootIds(result, rootIds);
  //   classificationContainer.list = classificationAmnen as iClassificationItem[];
  //   syncClassificationContainer(classificationContainer);
  //   setClassificationContainerAmne(classificationContainer);

  //   //Hantera behörigheter
  //   classificationContainer = getEmptyClassificationContainer();
  //   rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_BEHORIGHET_CLASSIFICATION_KEY);
  //   let classificationBehorigheter = getClassificationByRootIds(result, rootIds);
  //   classificationContainer.list = classificationBehorigheter as iClassificationItem[];
  //   syncClassificationContainer(classificationContainer);
  //   setClassificationContainerBehorighet(classificationContainer);

  //   //Hantera frågetyper
  //   classificationContainer = getEmptyClassificationContainer();
  //   //TODO: Remove this filter hack and implement it in the API
  //   rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_FRAGETYP_CLASSIFICATION_KEY);
  //   let classificationsFrageTyp = getClassificationByRootIds(result, rootIds);
  //   classificationContainer.list = classificationsFrageTyp as iClassificationItem[];
  //   syncClassificationContainer(classificationContainer);

  //   setClassificationContainerFragetyp(classificationContainer);

  //   return result;
  // };

  // const getLearningObjectives = async () => {
  //   const data = await fetchLearningObjectives();
  //   const dataIndicators = await fetchLearningObjectiveIndicatorClassifications();
  //   let classificationContainer: ClassificationContainer = getEmptyClassificationContainer();
  //   classificationContainer.list = data as iClassificationItem[];
  //   // data.forEach((x) => (x.metadata = { Level: x.levelId, Type: x.typeId }));
  //   data.forEach((x) => {
  //     const itemIndicatorIds: string[] = x.indicators.map((i: any) => i.id);
  //     const itemIndicatorClassifications = dataIndicators.filter((x: any) => itemIndicatorIds.includes(x.indicatorId));
  //     x.metadata = {
  //       Level: x.levelId,
  //       Type: x.typeId,
  //       LearningObjectiveClassifications: itemIndicatorClassifications,
  //     };
  //   });
  //   syncClassificationContainer(classificationContainer, undefined, false);
  //   setContainerLearningObjectives(classificationContainer);

  //   return data;
  // };

  const fetchFilters = async () => {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL as string);

    let filters: iCustomFilterDtoExtended[] =
      ((await client.settingGetLearningObjectFilters(userId)) as iCustomFilterDtoExtended[]) ?? [];
    filters.forEach((x) => (x.isNew = false));
    setCustomFilters(filters);

    return filters;
  };

  const init = async () => {
    await fetchFilters();

    let subTypes: iContainerSubTypeDtoExtended[] = await fetchContainerSubTypes(CONTAINER_TYPE_INTERACTION);
    setContainerSubTypesLearningObject(subTypes.filter((x) => x.id != 0));

    let configurationData: iConfiguration = await fetchConfiguration("ClassificationList");
    configurationData.learningObjectives = null;
    setConfigData(configurationData);

    // const classifications = await getClassifications(configurationData);

    // let classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "amnen")
    // );
    // setAmnen(classification);

    // classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "behorigheter")
    // );
    // setBehorigheter(classification);

    // classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "andamal")
    // );
    // setFragetyper(classification);

    // await getLearningObjectives();
    //console.log("learningObjectives", learningObjectives);

    const searchClient = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);

    const organisationerreply = await searchUtilities.getOrganisationer(searchClient);
    setOrganisationer(organisationerreply.rows);
  };

  useEffect(() => {
    init();
  }, []);

  const fetchGetLatestLearningObjects = async () => {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let getLearningObjectRequest: GetLearningObjectRequest = new GetLearningObjectRequest();
    let getMyLatestItemsResponse: GetMyLatestItemsResponse = await client.settingGetLatestLearningObjects(
      getLearningObjectRequest
    );
    return getMyLatestItemsResponse.myLatestItemDtos!.map((x) => x.id);
  };

  const gridDataLoaded = async (data: any[]) => {
    const updatedData = data.map((item: any) => ({
      ...item, // Spread the existing properties of the object
    }));

    let newUpdatedData: any[] = [];
    let latestData: any[] = [];
    let latest = await fetchGetLatestLearningObjects();

    updatedData.forEach((x) => {
      if (latest.includes(x.id)) {
        latestData.push(x);
      } else {
        newUpdatedData.push(x);
      }
    });

    newUpdatedData = latestData.concat(newUpdatedData);

    // hämta namnen för containerSubtype
    newUpdatedData.forEach((d) => {
      let name: string =
        containerSubTypesLearningObject!.find((x) => x.id == d.containerSubtype!)?.name ??
        d.containerSubtype.toString();
      d.containerSubtypeName = getTranslation(name);
    });

    setGridData(newUpdatedData);
  };

  const onSearchKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterClick(e);
    }
  };

  const filterClick = (event: any) => {
    setRefreshGrid(refreshGrid + 1);
  };

  const clearFilterClick = (event: any) => {
    let newCustomFilter = getInitialCustomFilter();

    setSelectedCustomFilter(newCustomFilter);
    console.log("clear customFilter", newCustomFilter);

    setClassificationContainerBehorighet({ ...classificationContainerBehorighet!, selected: [] });
    setClassificationContainerAmne({ ...classificationContainerAmne!, selected: [] });
    setClassificationContainerFragetyp({ ...classificationContainerFragetyp!, selected: [] });
    setContainerLearningObjectives({ ...containerLearningObjectives!, selected: [] });
    setMediaCheckboxes(defaultMediaCheckboxes);
    setRefreshGrid(refreshGrid + 1);
  };

  const saveFilterClick = async (event: any) => {
    let client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let request: GetFilterRequest = new GetFilterRequest();
    request.customQuestionFilterDto = selectedCustomFilter;
    request.userId = userId;
    await client.settingAddLearningObjectFilter(request);
    let filters: iCustomFilterDtoExtended[] = await fetchFilters();

    let newSelected: iCustomFilterDtoExtended = filters.find((x) => x.name == selectedCustomFilter.name)!;
    setSelectedCustomFilter(newSelected);
  };

  const deleteFilterClick = async (event: any) => {
    let client = new Client(appConfig.REACT_APP_LCMS_API_URL);

    await client.settingDeleteLearningObjectFilter(userId, selectedCustomFilter.name);
    await fetchFilters();

    setSelectedCustomFilter(getInitialCustomFilter());
  };

  const onElementValueChanged = (name: string, value: any) => {
    console.log("onElementValue", value);
    setFilterModel({
      ...filterModel,
      [name]: value,
    });
    // console.log("name", name);
    // console.log("value", value);
    if (name == "media") {
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          ...value,
        }) as iCustomFilterDtoExtended
      );
    } else {
      const newValue = value?.id ?? value;
      //const newValue = (value ? (value.id ? value.id : value) : value);
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          [name]: newValue,
        }) as iCustomFilterDtoExtended
      );
    }
  };

  const getUpdatedClassificationCopy = (classificationContainer: ClassificationContainer, selected: string[]) => {
    let copy: ClassificationContainer = { ...classificationContainer };
    copy.selected = [];

    selected.forEach((x) => {
      copy.selected.push(copy.list.find((y) => y.identifier == x)!);
    });

    return copy;
  };

  const onSelectedFilterChanged = (newFilter: iCustomFilterDtoExtended) => {
    if (newFilter == null) {
      newFilter = getInitialCustomFilter();
    }

    setSelectedCustomFilter(newFilter);

    // uppdatera träddialogerna
    setClassificationContainerAmne(getUpdatedClassificationCopy(classificationContainerAmne!, newFilter.amneGuids!));
    setClassificationContainerBehorighet(
      getUpdatedClassificationCopy(classificationContainerBehorighet!, newFilter.behorighetGuids!)
    );
    setClassificationContainerFragetyp(
      getUpdatedClassificationCopy(classificationContainerFragetyp!, newFilter.fragetypGuids!)
    );
    setContainerLearningObjectives(
      getUpdatedClassificationCopy(containerLearningObjectives!, newFilter.larandemalGuids!)
    );

    // uppdatera checkboxarna
    setMediaCheckboxes({
      harBild: newFilter.harBild!,
      harVideo: newFilter.harVideo!,
      ingenMedia: newFilter.ingenMedia!,
    });
  };

  const [showCustomDialog, setShowCustomDialog] = useState<any>(null);

  const searchExpansionPanel = (
    <FilterExpansionPanel
      controlsPerRow={4}
      elements={filterControls}
      onFilterClick={filterClick}
      onClearFilterClick={clearFilterClick}
      onSaveFilterClick={saveFilterClick}
      onDeleteFilterClick={deleteFilterClick}
      customFilters={customFilters}
      selectedCustomFilter={selectedCustomFilter}
      onSelectedFilterChanged={onSelectedFilterChanged}
    />
  );

  const mainGrid = () => (
    <MainGrid
      onRowClick={(event: GridRowClickEvent) => onSelectInteraction(event.dataItem)}
      searchUtilities={searchUtilities}
      configuration={configData}
      initialSort={initialSort}
      initialFilter={initialFilter}
      gridDataLoaded={gridDataLoaded}
      gridData={gridData}
      columns={GridColumns}
      // metadataPanel={MetadataPanel}
      setGridData={setGridData}
      customFilter={selectedCustomFilter}
      autoSubmitGridFilter={true}
      refresh={refreshGrid}
    />
  );

  const onRowClick = (row: any) => {
    const params: interactionParams = {
      interactionId: row.id,
      interactionType: 0,
      questionId: 0,
      templateId: 0,
    };

    openInteraction(params);
  };

  const onSelectInteraction = (interactionRow: any) => {
    if (props.onSelectInteraction) {
      props.onSelectInteraction(interactionRow);
    } else {
      onRowClick(interactionRow);
    }
  };

  return (
    <div>
      {showCustomDialog && <CustomDialog {...showCustomDialog} />}
      {/* {fragetyper && ( */}
      <SearchContainer
        searchType={MenuIndex.Interactions}
        mainGrid={mainGrid()}
        searchExpansionPanel={searchExpansionPanel}
        hideNewInteractionButton={props.onSelectInteraction}
      />
      {/* )} */}
    </div>
  );
};

export default Interactions;
